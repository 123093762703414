import React, { Component } from "react";
import {BsCheckCircle, BsExclamationTriangle} from 'react-icons/bs';
class ConfirmAlertAi extends Component {
	render(){
		return(
			<div className='custom-ui'>
	            <div className="react-confirm-alert-body text-center">
	              	<div><i className="pe-7s-img" style={{"fontSize":"40px", "color":"red"}}></i></div>
					<div className="row mb-sm">
						<BsExclamationTriangle style={{"fontSize":"33px", "color":"orange"}}/>
					</div>
	              	<h3 style={{"color":"#4f7bea", "fontSize":"20px"}}>{ this.props.header }</h3>
	              	<p className="marginFromTopPopUp" >{ this.props.description } </p>
					  {/* <textarea
							name="text_value"
							// value={this.props.val} // Adjusted to match the corrected state name
							onChange={this.props.textAreaChangeHandler}
							className="form-control font14"
							placeholder="Explain why"
						/> */}
	              	<div className="react-confirm-alert-button-group alignFlexButtons">
	                	<button onClick={()=>{
	                		if(this.props.onNo)
	                			this.props.onNo();
	                		this.props.onClosePro();
	                	}} className="btn btn-danger"><i className="pe-7s-close-circle"></i>{ this.props.noLabel }</button>
	                	{
	                		!this.props.doNotAutoCloseForYes
	                		?
	                			<button className="btn btn-success" onClick={() => {
			                    	this.props.onYes()
			                    	this.props.onClosePro()
			                	}}><i className="pe-7s-check"></i>{ this.props.yesLabel }</button>
			                :
			                	<button className="btn btn-success" onClick={() => {
			                    	this.props.onYes()
			                    	
			                	}}><i className="pe-7s-check"></i>{ this.props.yesLabel }</button>
	                	}
	                	
	                	
	              </div>
	            </div>
	        </div>
		);
	};
}



export default ConfirmAlertAi;
